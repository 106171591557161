import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NewYear, Year } from '../../interfaces/mask/year.interface';

/**
 * @service DocumentSearchService
 * @description Service responsible for handling document search operations.
 * Provides functionality to search and retrieve documents from the backend.
 */
@Injectable({
  providedIn: 'root',
})
export class YearService {
  /**
   * @constructor
   * @param {HttpClient} http - Angular's HttpClient for making HTTP requests
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * Retrieves all years
   * Makes a GET request to the year endpoint
   *
   * @returns {Observable<Year[]>} Observable of search results array
   */
  getYears(): Observable<Year[]> {
    return this.http.get<Year[]>(`${environment.baseUrl}/year`);
  }

  createYear(year: NewYear): Observable<Year> {
    return this.http.post<Year>(`${environment.baseUrl}/year`, year);
  }
}
