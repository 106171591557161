/**
 * @fileoverview Document search service that handles search operations
 * for retrieving document search results from the API.
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {BaseDoctype} from '../../interfaces/mask/docType.interface';

/**
 * @service DocumentSearchService
 * @description Service responsible for handling document search operations.
 * Provides functionality to search and retrieve documents from the backend.
 */
@Injectable({
  providedIn: 'root',
})
export class DoctypeService {
  /**
   * @constructor
   * @param {HttpClient} http - Angular's HttpClient for making HTTP requests
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * Retrieves all doctypes
   * Makes a GET request to the doctype endpoint
   *
   * @returns {Observable<BaseDoctype[]>} Observable of search results array
   */
  getDoctypes(): Observable<BaseDoctype[]> {
    return this.http.get<BaseDoctype[]>(`${environment.baseUrl}/doctype`);
  }
}
