<ejs-toast
  #element
  [content]="message()"
  [cssClass]="type()"
  [position]="position"
  [showCloseButton]="true"
  [target]="'#toast_container'"
  [timeOut]="type() === 'Error' ? 0 : 0"
  [title]="title()"
>
</ejs-toast>
