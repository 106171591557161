/**
 * @fileoverview Toast service that handles the dynamic creation, management,
 * and cleanup of toast notifications using Angular's component API.
 */

import {ApplicationRef, ComponentRef, createComponent, EnvironmentInjector, Injectable,} from '@angular/core';
import {ToastComponent} from '../layout/toast/toast.component';

/**
 * @service ToastService
 * @description Service responsible for managing toast notifications.
 * Handles dynamic component creation, lifecycle management, and automatic cleanup.
 * Supports different types of notifications with configurable messages and titles.
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private activeToasts: ComponentRef<ToastComponent>[] = [];

  constructor(
    private injector: EnvironmentInjector,
    private appRef: ApplicationRef,
  ) {}

  /**
   * Creates and displays a new toast notification
   * Automatically removes the toast after 5 seconds if not error
   *
   * @param {'Info' | 'Warning' | 'Error' | 'Success'} type - Type of toast notification
   * @param {string} title - Title text for the toast
   * @param {string} message - Main message content for the toast
   * @returns {void}
   */
  show(type: 'Info' | 'Warning' | 'Error' | 'Success', title: string, message = ''): void {
    // Create toast component
    const toastComponentRef = createComponent(ToastComponent, {
      environmentInjector: this.injector,
      hostElement: document.createElement('div'),
    });

    const toastContainer = document.querySelector('#toast_container');

    if (!toastContainer) {
      console.error('Toast container not found');
      return;
    }

    toastComponentRef.setInput('type', type);
    toastComponentRef.setInput('title', title);
    toastComponentRef.setInput('message', message);

    // Attach to DOM
    toastContainer.appendChild(toastComponentRef.location.nativeElement);

    // Attach to Angular's change detection
    this.appRef.attachView(toastComponentRef.hostView);
    this.activeToasts.push(toastComponentRef);

    // Auto remove after 5 seconds
    setTimeout(() => {
      if (type !== 'Error') this.removeToast(toastComponentRef);
    }, 5000);
    return;
  }

  /**
   * Removes a specific toast component and cleans up associated resources
   * Handles DOM cleanup, change detection detachment, and reference management
   *
   * @private
   * @param {ComponentRef<ToastComponent>} toastRef - Reference to the toast component to remove
   * @returns {void}
   */
  private removeToast(toastRef: ComponentRef<ToastComponent>): void {
    const index = this.activeToasts.indexOf(toastRef);
    if (index > -1) {
      // Detach from change detection
      this.appRef.detachView(toastRef.hostView);

      const toastContainer = document.querySelector('#toast_container');
      if (toastContainer) {
        toastContainer.removeChild(toastRef.location.nativeElement);
        toastRef.destroy();
        this.activeToasts.splice(index, 1);
      }
    }
    return;
  }
}
