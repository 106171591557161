import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuditType } from '../../interfaces/audit/audit.interface';

/**
 * @service DocumentSearchService
 * @description Service responsible for handling document search operations.
 * Provides functionality to search and retrieve documents from the backend.
 */
@Injectable({
  providedIn: 'root',
})
export class AuditTypeService {
  /**
   * @constructor
   * @param {HttpClient} http - Angular's HttpClient for making HTTP requests
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * Retrieves all auditTypes
   * Makes a GET request to the audittype endpoint
   *
   * @returns {Observable<AuditType[]>} Observable of search results array
   */
  getAuditTypes(): Observable<AuditType[]> {
    return this.http.get<AuditType[]>(`${environment.baseUrl}/audittype`);
  }
}
