import { Year } from '../../interfaces/indices/year.interface';
import { BaseDoctype } from '../../interfaces/mask/docType.interface';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { YearService } from '../../services/master-data/years.service';
import { computed, inject } from '@angular/core';
import { DoctypeService } from '../../services/master-data/doctype.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { combineLatest, map, tap } from 'rxjs';
import { AuditType } from '../../interfaces/audit/audit.interface';
import { AuditTypeService } from '../../services/master-data/audittype.service';

interface MasterDataState {
  docTypes: BaseDoctype[];
  years: Year[];
  auditTypes: AuditType[];
  loading: boolean;
}

const initialState: MasterDataState = {
  docTypes: [],
  years: [],
  auditTypes: [],
  loading: true,
};

export const MasterDataStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(
    (
      store,
      yearService = inject(YearService),
      doctypeService = inject(DoctypeService),
      auditService = inject(AuditTypeService),
    ) => ({
      updateYears: (years: Year[]) => {
        patchState(store, { years, loading: false });
      },

      loadMasterData: rxMethod<void>(() =>
        combineLatest<[BaseDoctype[], Year[], AuditType[]]>([
          doctypeService.getDoctypes(),
          yearService.getYears(),
          auditService.getAuditTypes(),
        ]).pipe(
          tap(() => patchState(store, { loading: true })),
          map(([docTypes, years, auditTypes]) => ({
            docTypes,
            years,
            auditTypes,
          })),
          tap((data: { docTypes: BaseDoctype[]; years: Year[]; auditTypes: AuditType[] }) =>
            patchState(store, {
              ...data,
              loading: false,
            }),
          ),
        ),
      ),

      clearMasterData: () =>
        patchState(store, {
          docTypes: [],
          years: [],
          auditTypes: [],
          loading: false,
        }),
    }),
  ),
  withComputed(({ docTypes, years, loading }) => ({
    hasData: computed(() => docTypes().length > 0 && years().length > 0),
    isLoading: computed(() => loading()),
    getDoctypeName: computed(() => (id: string) => {
      const types = docTypes();
      const doctype = types.find(x => x.id === id);
      return doctype?.name ?? id;
    }),
  })),
);
